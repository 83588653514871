<template>
<div v-if="$i18n.locale === 'ar'" class="container dirr my-md-8" style="max-width: 1261px !important;">
    <div class="panl-title mb-5">
                <img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" />
                <!-- <router-link :to="'/media?lang='+$i18n.locale" style="text-decoration: none !important;"> -->
                <a target="_blank" href="https://projects.alkafeel.net/" style="text-decoration: none !important;">
<div class="tl panl-title-text d-inline s17"> {{ $t("the_projects") }}</div>
                    </a>
                    
                <!-- </router-link> -->
            </div>
                <!-- <p class="tl my-4">{{ $t("index_projects_full") }}</p> -->
    <v-layout row>
        <v-flex lg6 md12 sm12 xs12>
            <div class="desc p-0" style="max-width: none;">
               

                <v-layout row class="mt-1 mx-1">
                    <v-flex lg4 md4 sm4 xs4 v-for="(c,i) in cats" :key="c">
                        
                        <div class="pro_cat" :style="$vuetify.theme.dark ? 'background: #393535' : 'background: #F5F5F5'">
                            <a v-if="i === 2" :href="'https://alkafeel.net/projects'" style="text-decoration: none;" target="_blank">
                                <img class="pro_img" :src="'https://projects.alkafeel.net/uploads/home_icons/projecs.png'"><br>
                                <div class="pro_cat_text" :style="$vuetify.theme.dark ? 'color: #FFF' : 'color: #000'">المشاريع الهندسية</div>
                            </a>
                            <a v-else :href="'https://projects.alkafeel.net/project?section_id='+c.id" style="text-decoration: none;">
                                <img class="pro_img" :src="'https://projects.alkafeel.net/uploads/home_icons/'+c.home_icons"><br>
                                <div class="pro_cat_text" :style="$vuetify.theme.dark ? 'color: #FFF' : 'color: #000'">{{ c.name }}</div>
                            </a>
                        </div>
                        
                    </v-flex>
                </v-layout>
                
            </div>
        </v-flex>

        <v-flex lg6 md12 sm12 xs12 class="mt-md-0 mt-6 vfg" pr-4>
            <swiper class="swiper2 swp dirr" ref="mySwiper" :options="swiperOptions2">
                <swiper-slide v-for="i in res" :key="i">
                    <v-img :src="i.image" light height="352" class="sw_item" >
                        <a class="fill-height" :href="'https://projects.alkafeel.net/project/'+i.id">
                            <div class="fill-height gradiant_img messiri_fonted">
                                <span class="sw_img_title" >{{i.name}} </span>
                                <div class="sw_img_cat" v-if="i.cat_name">
                                    <!-- <span><img width="10" :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span> -->
                                    {{ i.cat_name.name }}
                                </div>
                                <!-- <div class="sw_img_date"><img style="margin-left: 8px; margin-right: 8px;" src="/assets/img/date-green.svg">{{ i.created_at }}</div> -->
                            </div> 
                        </a>
                    </v-img>
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination-bullets" style="position: inherit;bottom: 0" slot="pagination"></div>
            </swiper>
        </v-flex>

    </v-layout>
    
</div>
</template>



<script>


export default {
    name: 'carrousel',
    data() {
        return {
            res:[],
            cats:[],
            swiperOptions2: {
                slidesPerView: "auto",
                spaceBetween:0,
                loop:false,
                keyboard: true,
                slidesPerView: "1",
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                autoplay: {
                    delay: 5500
                }
            },
        }
    },
    
    computed: {
        swiper() {return this.$refs.mySwiper.$swiper}
    },
    mounted() {
    },
    created () {
        this.initialize();
    },
       
    methods:{
        initialize() {
            this.$axios.get("projects").then(res => { this.res = res.data; })
            this.$axios.get("projects/cats").then(cats => { this.cats = cats.data; })
        },
    },
}
</script> 

<style scoped>

    .main_title {
        color: #000;
        font-size: 24px;
        font-family: 'cairo_bold';
        vertical-align: super;
        text-decoration: none;
    }
    .swiper-pagination-bullet-active {
        background: #000!important;
    }
    .pro_img {
        margin: 15% auto 5px;
        width: 52px;
    }
    .pro_cat_text {
        text-decoration: unset;
        font-family: 'readex';
        font-size: 16px;
        color: #333333;
    }
    .pro_cat {
        min-height: 170px;
        vertical-align: middle;
        text-align: center;
        margin: 0 0 8px 8px;
        min-width: 190px;
        background-color: #FFF;
        border-radius: 4px;
    }
    .swiper {
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .swiper-slide img {
        max-height: 210px;
    }
    .height {
        width: 100%;
        height: 100%;
    }
    .gradiant_img {
        background-image: linear-gradient(to top, #0C0F12 0%, transparent 82px);
    }
    .sw_img_date {
        color: white;
        font-family: 'readex';
        font-size: 14px;
        text-decoration: none;
        position: absolute;
        bottom: 8px;
        left: 15px;
    }
    .sw_img_cat {
        color: black;
        font-family: 'readex';
        font-size: 12px;
        font-weight: 400;
        position: absolute;
        top: 6px;
        background: #ffffffb9;
        padding: 5px;
        margin-left: 6px;
        margin-right: 6px;
        border-radius: 3px;

    }
    .sw_img_title {
        color: white;
        font-family: 'readex';
        font-size: 20px;
        font-weight: 400;
        position: absolute;
        bottom: 16px;
        left: 16px;
        right: 16px;
        text-align: right;
        text-shadow: 1px 1px 2px #000;
    }
    .sw_item {
        vertical-align: bottom;
        border-radius: 3px;
    }



    @media only screen and (max-width: 600px) {

    .vfg{
        padding-right:0px !important;
    }
       
    .pro_cat{
        min-height: auto !important;
        vertical-align: middle;
        text-align: center;    
        margin: 3px  !important;;
        min-width: auto  !important;;
        background-color: #FFF;
        height: 105px;
    }
    .s17 {
        font-size: 17px!important;
    }

    .pro_img {
    margin: 15% auto 5px;
    width: 32px;
}
}


.p-0 {
    padding: 0 !important;
}

</style>